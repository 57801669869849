export const MAIN_CONTAINER_WIDTH = '1200px'
export const HEADER_HEIGHT = '104px'
export const HEADER_MOBILE_HEIGHT = '70px'
export const HEADER_BACKGROUND_HEIGHT = '80px'
export const HEADER_BACKGROUND_MOBILE_HEIGHT = '70px'
export const HEADER_BACKGROUND_WIDTH = '1280px'
export const PROMO_BANNER_HEIGHT = '62px'
export const PROMO_BANNER_TABLET_HEIGHT = '116px'
export const PROMO_BANNER_MOBILE_HEIGHT = '145px'
export const BUTTON_HEIGHT = '52px'
export const BUTTON_SMALL_HEIGHT = '46px'
